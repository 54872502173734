import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import ZoomSlider from './slider';
import * as d3 from 'd37';


const D3Chart = styled.svg`
  width: 100%;
  height: calc( 100vh - 40px );

  .title {
    font: 12px sans-serif;
    pointer-events: none;
  //  text-shadow: 0 5px 0 #fff, 5px 0 0 #fff, 0 -5px 0 #fff, -5px 0 0 #fff;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  .title-group-2 {
    fill: green;
  }

  rect {
    fill: rgb(129, 129, 129);
    stroke: none;
  }

  .link {
    fill: none;
    stroke: #666;
    stroke-width: 1.5px;
  }

  #licensing {
    fill: green;
  }

  .link.licensing {
    stroke: green;
  }

  .link.resolved {
    stroke-dasharray: 0,2 1;
  }

  text {
    fill: #111;
    stroke: #111;
    stroke-width: 1px;
  }

  circle {
    fill: #181818;
    stroke: #111;
    stroke-width: 1.5px;
  }

  text.clickable-title {
    cursor: pointer;

    &.hidden {
   //   display: none;
    }

  }

  .category {
    /* circle {
      display: none;
    } */
  }

  .item {
    text {
      display: none;
    }
    rect  {
      display: none;
    }
  }


  .category.node-group {
    z-index: 10;
  }


`

//https://www.freecodecamp.org/news/how-to-get-started-with-d3-and-react-c7da74a5bd9f/
function  Chart(props) {
  const loaded3 = React.useRef(false);
  const [loaded4, set_loaded4]= React.useState(false);
  const [hoverCategoty, set_hoverCategory]= React.useState(null);
  const [hoverItem, set_hoverItem]= React.useState(null);
  const [dragging, set_dragging]= React.useState(false);
  const [zoom, set_zoom]= React.useState('0.46293734669862746');
  const [lastZoomSlider, set_lastZoomSlider]= React.useState(false);
  const data = props.data;
  const chosenNode = props.chosenNode;
  const clickTitleEvent = new Event("build");

  useEffect(() => {
    if (!loaded3.current) {
      createChart3(data);
    }
  }, [loaded3])

  useEffect(() => {
    console.log('data changed', data)

    // createChart3(data);
  }, [data])


  useEffect(() => {
    async function readyToLoadEventListeners() {
      let tmp = false;
      while(!tmp) {
        await new Promise(r => setTimeout(r, 500));
        const ready = !!document.getElementById("id-node-cat-collaborators");
        if(ready) {
          set_loaded4(true);
          tmp = true;
        }
      }
    }
    if (!loaded4) {
      readyToLoadEventListeners();
    }
  }, [loaded4])

  useEffect(() => {
    if(!loaded4) {
      return;
    }
    function categotyUp (event) {
      set_hoverCategory(event.target.id.replace('id-node-',''));
      set_hoverItem(null);
    }

    function categotyDown (event) {
      set_hoverCategory(null)
      set_hoverItem(null);
    }

    function itemUp (event) {
      set_hoverItem(event.target.id.replace('id-node-',''));
      set_hoverCategory(null);
      props.onHover(event.target.id.replace('id-node-',''));
    }

    function itemDown (event) {
      set_hoverCategory(null)
      set_hoverItem(null);
    }

    const categories = document.getElementsByClassName('node-group category');
    const items = document.getElementsByClassName('node-group item');
    for(let i = 0; i < categories.length; i++) {
      const elem = categories[i];
      elem.addEventListener(
        "mouseenter",
        categotyUp,

      );
      elem.addEventListener(
        "mouseleave",
        categotyDown,
      );
    }
    for(let i = 0; i < items.length; i++) {
      const elem = items[i];
      elem.addEventListener(
        "mouseenter",
        itemUp,

      );
      elem.addEventListener(
        "mouseleave",
        itemDown,
      );
    }

    return () => {
      for(let i = 0; i < categories.length; i++) {
        const elem = categories[i];
        elem.removeEventListener(
          "mouseenter",
          categotyUp,

        );
        elem.removeEventListener(
          "mouseleave",
          categotyDown,
        );
      }
      for(let i = 0; i < items.length; i++) {
        const elem = items[i];
        elem.removeEventListener(
          "mouseenter",
          itemUp,

        );
        elem.removeEventListener(
          "mouseleave",
          itemDown,
        );
      }
    }

  }, [loaded4])

  function fireClick(event) {
    const id = event.target.id;
    console.log('fired', id);
    props.onClick(id);
  }

  let simulation;

  async function createChart3(data) {
    console.log('createChart3', data)
    if(data.nodes.length === 0) return;
    loaded3.current = true;

    const width = 1500;
    const height = 930;

    // Specify the color scale.
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // The force simulation mutates links and nodes, so create a copy
    // so that re-evaluating this cell produces the same result.
    const links = data.links.map(d => ({ ...d }));
    const nodes = data.nodes.map(d => ({ ...d }));

    // Create a simulation with several forces.
    simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links)
        .id(d => d.id)
         .distance(300)
      )
      //.force("collide", d3.forceCollide(-25))
      // .force("center", d3.forceCenter()
      // )
      .force("charge", d3.forceManyBody()
        .strength(-2000)
        // .distanceMin(150)
        // .distanceMax(500)
      )
      .force("x", d3.forceX())
      .force("y", d3.forceY());


    //  const link = d3.forceLink(links).id((d) => d.id);

    // Create the SVG container.
    const svgMain = d3.select(".d3-chart")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
    //   .attr("style", "max-width: 100%; height: auto;");

    const svg = svgMain
      .append('g')
    //  .attr("transform", 'translate(33.84449295888152,-4.803085204321945) scale(0.46293734669862746)')

    // Make it zoomable and movable
    const handleZoom = ({transform}) => {
      svg.attr("transform", transform);


      // const transform = e.transform;
      // const newZoom = transform.k;
      // console.log('newZoom', newZoom)
      // set_zoom(newZoom);
      // return svg.attr('transform', transform)
    }
    const zoom = d3.zoom().scaleExtent([0.1, 2]).on('zoom', handleZoom);
    svgMain.call(zoom);


    // svg.transition().duration(200).call(
    //   zoom.transform,
    //   d3.zoomIdentity.scale(0.1)
    // );

    const labelsOnly = svg.append("g")
      .attr("class", "title-group")
      .attr("style", "display: none;")
      .selectAll("circle")
      .data(nodes)
      .join("text")
      .text(d => d.name)
      .attr("attr-id", d => d.id);

    const labelsOnlyNodes = labelsOnly._groups[0];
    let lengthsPx = {};

    // //wait for all to load
    // await new Promise(r => setTimeout(r, 1000));

    for (let no = 0; no < labelsOnlyNodes.length; no++) {
        const width = labelsOnlyNodes[no].getBoundingClientRect().width;
        lengthsPx[labelsOnlyNodes[no].getAttribute('attr-id')] = width;
        console.log(labelsOnlyNodes[no].__data__.id, width)
     // lengthsPx[labelsOnlyNodes[no].getAttribute('attr-id')] = labelsOnlyNodes[no].getComputedTextLength();
      //   lengthsPx[labelsOnlyNodes[no].getAttribute('attr-id')] = labelsOnlyNodes[no].getBBox().width ;
    //  lengthsPx[labelsOnlyNodes[no].getAttribute('attr-id')] = labelsOnlyNodes[no].clientWidth;
    // lengthsPx[labelsOnlyNodes[no].getAttribute('attr-id')] = labelsOnlyNodes[no].offsetWidth ;
    }

    console.log('lengthsPx', lengthsPx)

    let length_no = 0;

    // Add a line for each link, and a circle for each node.

    const link = svg.append("g")
      .attr("id", "link")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("path")
      .attr('fill', 'none')
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", d => d.special ? "8 8" : 0)
      .attr("class",  d => `line-group link-to-${d.target.id} link-from-${d.source.id} ${d.special ? 'special' : ''}`);

      const curve = d3.line().curve(d3.curveNatural);

    // const link2 = svg.append("g")
    //   .attr("id", "link")
    //   .attr("stroke", "#999")
    //   .attr("stroke-opacity", 0.6)
    //   .selectAll("line")
    //   .data(links)
    //   .join("path")
    //   .attr("stroke-width", 3)
    //   .attr("class",  d => `line-group link-to-${d.target.id} `);

    console.log('chosenNode', chosenNode)

    const node = svg.append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .attr("id", "node")
      .selectAll("circle")
      .data(nodes)
      .join("g")
      .attr("id", "2nd-g")
      .attr("class",  d => `
        node-group
        group-${d.target}
        ${chosenNode === `id-text-${d.id}` ? 'selected' : 'not-selected'}
        ${d.category ? 'category' : 'item'}
        ${d.relations ? JSON.stringify(d.relations).replace('["', ' relation-').replaceAll('","', ' relation-').replace('"]', '') + ' relations' : '' }
      `)
      .attr("id", d => `id-node-${d.id}`)
      .append("rect")
      .attr("width", (d)=>{
        const width = d.width ? d.width : lengthsPx[d.id];
        return width + 20;
      })
      .attr("rx", 15)
      .attr("height", 30)
      .attr("class", d => [
        `${`id-text-${d.target ? d.target : ''}` !== props.chosenNode ? 'hidden' : 'visible'}`,
        `${`id-text-${d.id}` === props.chosenNode ? 'chosen' : ''}`,
      ].join(" "));

    const labels2 = svg.selectAll("g.node-group")
      .append("text")
      .data(nodes)
      // .attr("class", d => [
      //   `clickable-title`,
      //   `${`id-text-${d.target ? d.target : ''}` !== props.chosenNode ? 'hidden' : 'visible'}`,
      //   `${`id-text-${d.id}` === props.chosenNode ? 'chosen' : ''}`,
      // ].join(" "))
      .attr("id", d => `id-text-${d.id}`)
      // .on("click", fireClick)
      .text(d => d.name);

    const circles = svg.selectAll("g.node-group")
      .append("circle")
      .attr("r", 3)


  //  Add a drag behavior.
    node.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    circles.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    labels2.call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    //when drag, hide



    // Set the position attributes of links and nodes each time the simulation ticks.
    simulation.on("tick", () => {

      link
        .attr("d", linkArc)
      //   .attr("x1", d => {
      //     // if(d.target.id === 'Collaborators') return d.source.x + 55
      //     // console.log('x1', d)
      //     return d.source.x
      //   })
      //   .attr("y1", d => d.source.y)
      //   .attr("x2", d => {
      //     // if(d.target.id === 'Collaborators') return d.target.x  + 55
      //     // console.log('x1', d)
      //     return d.target.x
      //   })
      //   .attr("y2", d => {
      //     // if(d.target.id === 'Collaborators') return d.target.y  + 15
      //     // console.log('x1', d)
      //     return d.target.y
      //   });


      node
        .attr("cx", d => {
        //  console.log('cx', d)
          return d.x
        })
        .attr("cy", d => d.y);

      circles
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);

  //    labels.attr("transform", transform);
      labels2.attr("transform", transformLabels);
      node.attr("transform", transform);

    });

    // Reheat the simulation when drag starts, and fix the subject position.
    function dragstarted(event) {
      set_dragging(true);
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    // Update the subject (dragged node) position during drag.
    function dragged(event) {
    //  console.log('dragged', event)
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    // Restore the target alpha so the simulation cools after dragging ends.
    // Unfix the subject position now that it’s no longer being dragged.
    function dragended(event) {
      set_dragging(false);
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function transform(d) {
      const width = d.width ? d.width : lengthsPx[d.id];
      const x = d.x - (width / 2) - 10;
      const y = d.y - 40;
      if(!!x && !!y) {
        return "translate(" + x + "," + y + ")";
      }
    }

    function transformLabels(d) {
      const width = d.width ? d.width : lengthsPx[d.id];
      const x = d.x - (width / 2);
      const y = d.y - 20;
      if(!!x && !!y) {
       return "translate(" + x + "," + y + ")";
      }
    }

    function linkArc(d) {
      var dx = d.target.x - d.source.x,
          dy = d.target.y - d.source.y,
          dr = Math.sqrt(dx * dx + dy * dy);
      return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
    }


    // When this cell is re-run, stop the previous simulation. (This doesn’t
    // really matter since the target alpha is zero and the simulation will
    // stop naturally, but it’s a good practice.)
    //invalidation.then(() => simulation.stop());

    return svg.node();
  }

  function handleZoomChange(event) {
    // console.log('e.target.value', event);
    // const newZoom = (event.target.value / 100 * 1.9) + 0.1;
    // console.log('newZoom', newZoom);
    // set_zoom(newZoom);
    // set_lastZoomSlider(true);
    // const currentTransform = document.querySelector('.d3-chart > g')['transform'];
    // const e = currentTransform.baseVal[0].matrix.e;
    // const f = currentTransform.baseVal[0].matrix.f;


    // document.querySelector('.d3-chart > g')['transform'].animVal[0].matrix.a = newZoom;
    // document.querySelector('.d3-chart > g')['transform'].animVal[0].matrix.d = newZoom;
    // document.querySelector('.d3-chart > g')['transform'].baseVal[1].matrix.a = newZoom;
    // document.querySelector('.d3-chart > g')['transform'].baseVal[1].matrix.d = newZoom;

    // d3.select('.d3-chart > g').attr('transform',`translate(${e},${f}) scale(${newZoom})`);

  }


  return (
    <>
      <D3Chart
        className='d3-chart'
        width={'1000'}
        height={'1000'}
      />
      <style>
        {
        !dragging &&
        `
          #id-node-${hoverCategoty} > rect {
            fill: #FF6F6F;
            stroke: #FFB1B1;
          }
          line.link-to-${hoverCategoty},
          path.link-to-${hoverCategoty}
           {
            stroke: #FF6F6F;
          }

          /* g.group-${hoverCategoty} > circle {
             display: none;
          } */

          g.group-${hoverCategoty}.item > rect {
            fill: #FFB1B1;
            stroke: #FF6F6F;
          }
          g.item.group-${hoverCategoty} > text,
          g.item.group-${hoverCategoty} > rect
          {
            display: block;
          }

          g#id-node-${hoverItem}.item > text,
          g#id-node-${hoverItem}.item > rect
          {
            display: block;
          }

         /*  g#id-node-${hoverItem}.item > circle
          {
            display: none;
          }*/

          g#id-node-${hoverItem}.item.relations > rect {
            fill: #FF6F6F;
            stroke: #FFB1B1;
          }

          g.relation-${hoverItem}.item > rect,
          g.relation-${hoverItem}.item  > text {
            display: block;
          }
          g.relation-${hoverItem}.item > rect {
            fill: #FFB1B1;
            stroke: #FF6F6F;
          }
             /*
          g.relation-${hoverItem}.item  > circle {
            display: none;
          } */


          line.link-from-${hoverItem},
          path.link-from-${hoverItem},
          line.link-to-${hoverItem},
          path.link-to-${hoverItem}
          {
            stroke: #FF6F6F;
          }


        `}
      </style>
      {/* <ZoomSlider
        value={parseInt(((zoom-0.1)/1.9)*100)}
        onChange={handleZoomChange}
      /> */}
    </>
  );
}

export default Chart;

