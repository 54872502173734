import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { BrowserRouter , Router, Routes, Route, useLocation } from "react-router-dom";
import { WP_API_URL } from './config';
import FlyingVideos from "./components/Intro/FlyingVideos";
import Title from "./components/Intro/Title";
import Home from "./pages/Homepage"
import Layout from "./components/Layout"
import Header from "./components/Header";
import Morphing from './components/Morphing';
import Chapters from './components/Chapters'
import Chapter from './pages/Chapter'
import Intro from './components/Intro'

// Pages
import HomePage from './pages/Homepage';
import ChapterPage from './pages/Chapter/index';
import About from './pages/About';
import Chart from './pages/Chart';
import Collaborations from './pages/Collaborations';

import PageFlip from './pages/Chapter/PageFlip3';


const AppContainer = styled.div`
  &.page-chart {
    height: 100dvh;
    padding: 0;

  }
`

function App() {
  const location = useLocation();
  const [showIntro, set_showIntro] = useState(true);
  const [stopIntro, set_stopIntro] = useState(false);
  const [reloadCounter, set_reloadCounter] = useState(1);
  const [posts, set_posts] = useState(null);
  const [about, set_about] = useState(null);
  const [morphing, set_morphing] = useState({
    title: null,
    content: null,
  });
  const [postsContent, set_postsContent] = useState({});
  const [postsContentIds, set_postsContentIds] = useState([]);
  const [bookInfo, set_bookInfo] = useState({
    title: null,
    content: null,
    coverUrl: null,
    orderUrl: null,
    pdfUrl: null,
  });
  const [yellowBanner, set_yellowBanner] = useState({
    content: null,
    show: null,
    multipleContent: null,
  });

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch(`${WP_API_URL}posts?_embed&per_page=100`);
            const newData = await response.json();
        //    console.log(newData);
            set_posts(newData);
        } catch (e) {
            console.log(e);
        }
    };
    if (!posts) fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
       try {
        const response = await fetch(`${WP_API_URL}pages/8`);
        const newData = await response.json();
    //    console.log('set_morphing', newData)
        set_morphing({
          title: newData.title.rendered,
          content: newData.content.rendered.replaceAll('\n',''),
        });
       } catch (e) {
        console.log(e);
       }

    };
    if (!morphing.content) fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
       try {
        const response = await fetch(`${WP_API_URL}pages/234?_embed&acf_format=standard`);
        const newData = await response.json();
        const newBookInfo = {
          content: newData.content.rendered.replaceAll('\n', ''),
          coverUrl: newData["_embedded"]["wp:featuredmedia"][0]?.source_url,
          orderUrl: newData?.acf?.order_book_url,
          pdfUrl: newData?.acf?.book_pdf,
          review: newData?.acf?.review,
        };
        console.log('newBookInfo', newBookInfo)
        set_bookInfo(newBookInfo);
       } catch (e) {
          console.log(e);
       }
    };
    if (!bookInfo.title) fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
       try {
        const response = await fetch(`${WP_API_URL}pages/243?acf_format=standard`);
        const newData = await response.json();
        console.log('set_yellowBanner', newData)
        set_yellowBanner({
          content: newData.content.rendered.replaceAll('\n', ''),
          multipleContent: newData?.acf,
          show: newData?.acf?.show,
        });
       } catch (e) {
          console.log(e);
       }
    };
    if (!yellowBanner.content) fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
       try {
        const response = await fetch(`${WP_API_URL}pages/140`);
        const newData = await response.json();
        set_about(newData);
       } catch (e) {
        console.log(e);
       }
    };
      if (!about) fetchData();
  }, []);

  useEffect(() => {
      console.log('posts', posts);
  }, [posts]);

  async function fetchChapter(id) {
    try {

      // Main post
      console.log('fetchChapter', id)
      if(!id) return;
      id = parseInt(id);

      if(!postsContentIds.includes(id)) {
        const response = await fetch(`${WP_API_URL}posts/${id}`);
        const newData = await response.json();
        set_postsContent(prev => Object.assign(prev, {[id]: newData}));
        set_postsContentIds(prev => {
          if (prev.includes(id)) return prev
          else return [...prev, id]
        });
      }

      //Next and Previous post too
      if(posts && posts.length > 1){
        console.log('posts.length > 1')
        const index = posts.findIndex(post => post.id === id);
        let prevData, nextData;
        let prevId, nextId;
        if(index !== 0) {
          console.log('index', index)
          const prevIndex = index - 1;
          console.log('prevIndex', prevIndex)
          prevId = posts[prevIndex].id;
          if(!postsContentIds.includes(prevId)) {
            console.log('!postsContentIds.includes(prevId)')
            const response = await fetch(`${WP_API_URL}posts/${prevId}`);
            prevData = await response.json();
          }
        }
        if(index !== posts.length-1) {
          const nextIndex = index + 1;
          nextId = posts[nextIndex].id;
          if(!postsContentIds.includes(nextId)) {
            console.log('!postsContentIds.includes(nextId)')
            const response = await fetch(`${WP_API_URL}posts/${nextId}`);
            nextData = await response.json();
          }
        }

        if(nextData && prevData) {
          console.log('if(nextData && prevData) {')
          set_postsContent(prev => Object.assign(prev, {[prevId]: prevData, [nextId]: nextData}));
          set_postsContentIds(prev => [...prev, prevId, nextId]);
        } else if (nextData) {
          console.log('if(nextData) {')
          set_postsContent(prev => Object.assign(prev, {[nextId]: nextData}));
          set_postsContentIds(prev => [...prev, nextId]);
        } else if (prevData) {
          console.log('if(prevData) {')
          set_postsContent(prev => Object.assign(prev, {[prevId]: prevData}));
          set_postsContentIds(prev => [...prev, prevId]);
        }

      }

    } catch (e) {
      console.error('Error while fetching chapter', e);
    }
  };

  useEffect(() => {
    console.log('postsContentIds', postsContentIds);
  }, [postsContentIds]);

  return (
    <AppContainer className={`App page-${location.pathname.replace('/','')}`}>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route
              path="/"
              element={
                <HomePage
                  posts={posts}
                  morphing={morphing}
                  bookInfo={bookInfo}
                  yellowBanner={yellowBanner}
                />
              } />
            {/* <Route path="Morphing" element={
              <Morphing
                posts={posts}
                morphing={morphing}
              />}
            /> */}

            <Route path="/chapter"  >
              <Route
                path="/chapter/:id"
                element={
                  <ChapterPage
                    id={`ChapterPage_0_${reloadCounter}`}
                    key={`ChapterPage_1_${reloadCounter}`}
                    posts={posts}
                    set_postsContent={set_postsContent}
                    postsContent={postsContent}
                    postsContentIds={postsContentIds}
                    fetchChapter={fetchChapter}
                  />
                }
              />
            </Route>

            <Route path="/about"  element={<About content={about}/>} />

            <Route path="/chart"  element={<Chart />} />

            <Route path="/collaborations"  element={<Collaborations />} />

          </Route>
        </Routes>
    </AppContainer>
  );
}

export default App;
