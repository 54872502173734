import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { data } from './data'


const Content = styled.div`
  width: 300px;
  position: absolute;
  top: 48px;
  right: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  background-color: lightgray;
  padding: 5px;
  div.top {
    display: flex;
    gap: 8px;
    height: 54px;
    h1 {
      font-size: 15px;
      line-height: 1.5;
      padding-top: 2px;
      padding-left: 5px;
      height: 44px;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
    }
    .close {
      width: 24px;
      height: 24px;
    }
  }

  img {
    width: 100%;
  }
  div.bottom {
    background: white;
    padding: 8px;
    border-radius: 4px;
    min-height: 40px;

  }
`;


const Placeholder = styled.div`
  width: 300px;
  height: 350px;
`;


const Space = styled.div`
  height: 16px;
`;

const Link = styled.a`
    text-decoration: none;
    margin-right: 16px;
`;

function Card(props) {
  const uuid = props.uuid;
  const cardData = data.nodes.find(node => node.id === uuid);
  if (cardData) {
    return (
      <Content>
        <div className='top'>
          <h1>
            {cardData.name}
          </h1>
          {/* <IconButton
            className="close"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton> */}
        </div>
        {
          (cardData.body || cardData.urls) &&
          <div className='bottom'>
            {cardData.body}
            {cardData.body && cardData.urls && <Space/>}
            {
              cardData.urls && cardData.urls.map((elem, index)=>
                <Link href={elem} target="_blank">Open Link ↗</Link>
              )
            }
          </div>
        }
      </Content>
    );
  } else {
    return (
      <Placeholder />
    );
  }

}

export default Card;

