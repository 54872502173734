import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import ReactPlayer from "react-player";

const Container = styled.div`
    /* width: 100vw; */
    height: 100dvh;
    margin-top: 2px;
    padding-right: 8px;
   // padding-top: 5px;
    padding-left: 8px;
  //  margin-top: 3px;
    width: calc( 100% - 16px );
 //   color: red;
   // font-size: 26px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    user-select: none;
    font-size: 36px;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    flex-wrap: wrap;

    &.stopIntro {
        animation: slide-heder-to-top ${props => props.titleAnimationTime}ms forwards;
        animation-delay: 0s;
    }

    .letter {
       visibility: hidden;
       &.visible{
        visibility: visible;
       }
    }


    .word {
        white-space: nowrap;
    }

    @keyframes slide-heder-to-top {
        100% {
            height: ${props => props.headerHeight ? props.headerHeight : 40}px;
            font-size: 15px;
            margin-top: 0;
        }
    }
    @keyframes hide-title {
        100% {
            visibility: hidden;
        }
    }

    @media screen and ( max-width: 601px ) {
        font-size: 26px;
    }

    @media screen and ( max-width: 450px ) {
        font-size: 15px;
    }

`

let letterDone = 0;

function Header(props) {
    const [runAnimation, set_runAnimation] = useState(true);
    const [stopIntro, set_stopIntro] = useState(false);
    const [normalHeader, set_normalHeader] = useState(false);
    const [headerHeight, set_headerHeight] = useState(null);
    const [fasterInterval, set_fasterInterval] = useState(false);
    const [finishedTypingTitle, set_finishedTypingTitle] = useState(false);
    useEffect(() => {
        if(!headerHeight) {
            const HeaderHeight = document.getElementById('Header').offsetHeight;
            set_headerHeight(HeaderHeight);
            console.log('staring height', HeaderHeight);
        }
        const handleResize = () => {
            const HeaderHeight = document.getElementById('Header').offsetHeight;
            set_headerHeight(HeaderHeight);
            console.log('title height', HeaderHeight);
        }
        setTimeout(handleResize, 250);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
          document.getElementsByClassName(`letter${letterDone}`)[0].classList.add("visible");
        //   if(letterDone === 13) {
        //     set_fasterInterval(true);
        //     clearInterval(interval);
        //     return;
        //   }
          if(letterDone === 25) {
            clearInterval(interval);
            set_finishedTypingTitle(true);
            return;
        }
          letterDone++;
        }, 75);
        return () => clearInterval(interval);
      }, []);

      useEffect(() => {
        console.log('letterDone', letterDone)
      }, [letterDone]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if(!fasterInterval) return;
    //         document.getElementsByClassName(`letter${letterDone}`)[0].classList.add("visible")
    //         if(letterDone === 25) {clearInterval(interval); return;}
    //         letterDone++;
    //     }, 150);
    //     return () => clearInterval(interval);
    // }, [fasterInterval]);

    const handleClick = () => {
        props.onClick();
        set_stopIntro(true);
        setTimeout(()=>{set_normalHeader(true)} , props.titleAnimationTime);
    };

    return (
        <Container
            className={`Header${stopIntro ? ' stopIntro' : ''}${normalHeader ? ' normalHeader' : ''}`}
            onClick={handleClick}
            titleAnimationTime={props.titleAnimationTime}
            headerHeight={headerHeight}
        >
            {
                // finishedTypingTitle ?
                //     <span className='notTypedTitle'>Roma Jam Session art Kollektiv</span>
                //     :
                <>
                    <span className='word'>
                        <span className='letter letter0'>R</span>
                        <span className='letter letter1'>o</span>
                        <span className='letter letter2'>m</span>
                        <span className='letter letter3'>a</span>
                    </span>
                    <span>&#160;</span>
                    <span className='word'>
                        <span className='letter letter4'>J</span>
                        <span className='letter letter5'>a</span>
                        <span className='letter letter6'>m</span>
                    </span>
                    <span>&#160;</span>
                    <span className='word'>
                        <span className='letter letter7'>S</span>
                        <span className='letter letter8'>e</span>
                        <span className='letter letter9'>s</span>
                        <span className='letter letter10'>s</span>
                        <span className='letter letter11'>i</span>
                        <span className='letter letter12'>o</span>
                        <span className='letter letter13'>n</span>
                    </span>
                    <span>&#160;</span>
                    <span className='word'>
                        <span className='letter letter14'>a</span>
                        <span className='letter letter15'>r</span>
                        <span className='letter letter16'>t</span>
                    </span>
                    <span>&#160;</span>
                    <span className='word'>
                        <span className='letter letter17'>K</span>
                        <span className='letter letter18'>o</span>
                        <span className='letter letter19'>l</span>
                        <span className='letter letter20'>l</span>
                        <span className='letter letter21'>e</span>
                        <span className='letter letter22'>k</span>
                        <span className='letter letter23'>t</span>
                        <span className='letter letter24'>i</span>
                        <span className='letter letter25'>v</span>
                    </span>
                </>
            }
        </Container>
    );
  }

  export default Header;
